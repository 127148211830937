import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import HowCanWeHelpEr from "../../components/how-can-we-help-er";
import PhotoLeft from "../../components/photo-left";
import BeeBanner from "../../components/bee-banner";
import WhatWeDoHero from "../../components/what-we-do-hero";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { GoPrimitiveDot } from "react-icons/go";
import SVGFBEr1 from "../../components/SVG/flying-bee-er-1";
import SVGFBEr2 from "../../components/SVG/flying-bee-er-2";
import SVGERBgPoly from "../../components/SVG/er-bg-poly";

const EmployeeRelations = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "ER-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			rfgErImg: wpMediaItem(title: { eq: "RFG-ER-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			tailorMadeImg: wpMediaItem(title: { eq: "Tailor-Made-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const rfgErImg = data.rfgErImg?.localFile.childImageSharp.gatsbyImageData;
	const tailorMadeImg =
		data.tailorMadeImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Employee Relations",
				item: {
					url: `${siteUrl}/what-we-do/employee-relations`,
					id: `${siteUrl}/what-we-do/employee-relations`,
				},
			},
		],
	};

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="Employee Relations | Hive HR Solutions"
					description="Relax and let us take care of your employee relations"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/what-we-do/employee-relations`,
						title: "Employee Relations | Hive HR Solutions",
						description:
							"Relax and let us take care of your employee relations",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<section className="position-relative">
					<SVGFBEr1
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top-10 d-none d-lg-block "
					/>
					<WhatWeDoHero
						title="Employee relations"
						// showDesc
						// description="Relax and let us take care of your employee relations"
						img={
							<Row>
								<Col>
									<GatsbyImage
										className="w-100 tImage"
										alt={data.heroImg?.altText}
										image={heroImg}
									/>
								</Col>
							</Row>
						}
					/>
				</section>
				<section className="position-relative">
					<SVGERBgPoly
						style={{ zIndex: -2 }}
						className="position-absolute end-0 bottom--30 d-none d-lg-block "
					/>{" "}
					<HowCanWeHelpEr
						height="25rem"
						width="35rem"
						img={rfgErImg}
						imgAlt={data.rfgErImg?.altText}
						heading="Handling employee issues"
						text={
							<span>
								<p>
									As much as you want your staff to be happy in their job and
									comply with your company's policies and procedures, we know
									that sometimes issues crop up that need to be addressed and
									resolved according to company policy and employment
									legislation to safeguard you and your employees.
								</p>
								<p>
									If you are looking for a safe pair of hands from an
									experienced team of HR professionals, then you've come to the
									right place.
								</p>
							</span>
						}
						list={
							<span>
								<h4 className="pb-3">Disciplinaries and grievances</h4>
								<ul className="help-list ps-0">
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											{" "}
											Support implementing a straightforward disciplinary and
											grievance policy and procedure that is tailored to your
											business{" "}
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											{" "}
											Managing individual disciplinary or grievance cases with
											you or managing it for you
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											{" "}
											Ensuring any process is in line with internal policies and
											legislation{" "}
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											Managing each step of a discipline or grievance process,
											from investigation to outcome and any appeal process,
											while keeping you informed throughout{" "}
										</p>
									</li>
								</ul>
								<h4 className="pb-3">Restructures and redundancies</h4>
								<ul className="help-list ps-0">
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											{" "}
											Managing any size restructure and providing expertise
											throughout the process and all the necessary documentation
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											{" "}
											Supporting you with documenting the business case for any
											restructure{" "}
											{`(this is one of the most important stages, as the
									restructure will be based on this and the consultation process
									that follows)`}{" "}
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											The business case will detail why this restructure is
											necessary and what the impact will be{" "}
										</p>
									</li>
									<li className="d-flex align-items-start">
										<div style={{ minWidth: "33px" }}>
											<GoPrimitiveDot
												style={{ minWidth: "21px" }}
												className="text-primary mt-1 me-2 fs-4"
											/>
										</div>
										<p className="text-start">
											Managing consultation processes in a fair, structured and
											empathetic way, whether they involve two employees or
											hundreds{" "}
										</p>
									</li>
								</ul>
							</span>
						}
					/>
				</section>
				<section className="pb-5 pt-4 position-relative">
					<SVGFBEr2
						style={{ zIndex: -2, right: "15%" }}
						className="position-absolute  top--60 d-none d-lg-block"
					/>
					<PhotoLeft
						idLeft="Performance-Left"
						idRight="Performance-Right"
						height="33rem"
						width="28rem"
						img={tailorMadeImg}
						imgAlt={data.tailorMadeImg?.altText}
						heading="Performance management"
						text={
							<span>
								<p>
									Are you feeling frustrated by non-performing employees? Would
									you like to work with an HR consultancy company that takes the
									time to understand the performance {`issue(s)`} and advise you
									on the next steps?
								</p>
								<p>
									Hive HR Solutions can guide you through a performance
									improvement process that will ensure the employee is clear
									about the process, what they need to do and how they will be
									measured.
								</p>
								<p>
									{" "}
									We will provide all the documentation to support the process
									of improving the individual's performance and we will provide
									guidance when their performance doesn't improve.
								</p>
							</span>
						}
					/>
				</section>

				<section>
					<BeeBanner
						headline="Call now for advice on employee performance management "
						btnTxt="Call us"
						btnLink="tel:+441403627630"
					/>
				</section>
			</Layout>
		);
	}
};

export default EmployeeRelations;
